export function CardIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="m4.95,13.81h2.25c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-2.25c-.41,0-.75.34-.75.75s.34.75.75.75Z" />
      <path d="m4.95,11.5h4.49c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-4.49c-.41,0-.75.34-.75.75s.34.75.75.75Z" />
      <path d="m15.61,3.63H4.39c-1.34,0-2.43,1.09-2.43,2.43v7.86c0,1.34,1.09,2.43,2.43,2.43h11.23c1.34,0,2.43-1.09,2.43-2.43v-7.86c0-1.34-1.09-2.43-2.43-2.43Zm.93,10.3c0,.52-.42.93-.93.93H4.39c-.52,0-.93-.42-.93-.93v-5.43h13.1v5.43Zm0-7.49H3.45v-.37c0-.52.42-.93.93-.93h11.23c.52,0,.93.42.93.93v.37Z" />
    </svg>
  )
}
