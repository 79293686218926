import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { CardIcon } from '@/modules/shared/icons/CardIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface PointOfSaleSideBarItemProps extends SideBarItem {
  pointOfSaleRoutesMatch: boolean
}

export const PointOfSaleSideBarItem = ({
  pointOfSaleRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: PointOfSaleSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []

    if (userPermissions?.PURCHASER_CAN_MANAGE_POS_ITEMS) {
      sideNavItems.push({
        key: t('pointOfSale.newPosItem', 'New POS Item'),
        target: '/point-of-sale/items/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }

    sideNavItems.push(
      {
        key: t('pointOfSale.posItems', 'POS Items'),
        target: '/point-of-sale/items',
      },
      {
        key: t('pointOfSale.outlets', 'Outlets'),
        target: '/point-of-sale/outlets',
      }
    )

    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<CardIcon className="size-7" />}
      title={t('general.pointOfSale', 'Point of Sale')}
      activeColor={pointOfSaleRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="inventory-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
