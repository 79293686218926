import i18next from 'i18next'

import { UserAccessControlList } from '@/modules/access/types'
import { LowercaseObjectKeys } from '@/modules/shared/types/LowercaseObjectKeys'

export const PURCHASING_GRAPHQL_API = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/api/graphql`
export const GOODS_GRAPHQL_API = `${import.meta.env.VITE_APP_NINJA_API_HOST}/goods/api/graphql`
export const INVENTORY_GRAPHQL_API = `${import.meta.env.VITE_APP_NINJA_API_HOST}/inventory/api/graphql`
export const ACCESS_GRAPHQL_API = `${import.meta.env.VITE_APP_NINJA_API_HOST}/access/api/graphql`
export const PURCHASE_PLUS_GRAPHQL_API = `${import.meta.env.VITE_APP_NINJA_API_HOST}/graphql`
export const SOURCE_APPLICATION = 'ppnew'

// Enterprise links
export const ENTERPRISE_SIGN_IN_PAGE = `${import.meta.env.VITE_APP_NINJA_API_HOST}/access/users/sign_in?redirect_url=${window.location.origin}/`
export const ENTERPRISE_CREDIT_NOTES_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/credit_notes/`
export const ENTERPRISE_RECEIVING_NOTES_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/receiving_documents/`
export const ENTERPRISE_RECEIVING_TRANSFER = `${import.meta.env.VITE_APP_NINJA_API_HOST}/inventory/en/receiving_transfers`
export const ENTERPRISE_REQUISITION_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/requisitions`
export const ENTERPRISE_PURCHASE_ORDERS_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/purchase_orders`
export const ENTERPRISE_PURCHASING_CATALOGUES_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/catalogues`
export const ENTERPRISE_PURCHASING_USERS_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/users`
export const ENTERPRISE_SUPPLIER_RELATIONSHIP_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/supplier_relationships`
export const ENTERPRISE_SUPPLIER_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/traders/en/suppliers`
export const ENTERPRISE_GR_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/receiving_documents`
export const ENTERPRISE_PO_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/purchase_orders`
export const ENTERPRISE_INVOICE_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/invoices`
export const ENTERPRISE_STOCK_LOCATION_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/inventory/en/stock_locations`
export const ENTERPRISE_USERS_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/access/en/users`
export const ENTERPRISE_PRICED_CATALOGS_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/purchasing/en/priced_catalogues`
export const ENTERPRISE_SUPPLIER_CATALOGS_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/supply/en/catalogues`
export const ENTERPRISE_PURCHASER_RELATIONSHIP_URL = `${import.meta.env.VITE_APP_NINJA_API_HOST}/supply/en/purchaser_relationships`
export const PURCHASE_PLUS_TERMS_AND_CONDITIONS_URL = 'https://www.purchaseplus.com/terms-and-conditions'

export const requisitionRoutes = [{ path: '/requisitions/*' }]

export const purchaseOrderRoutes = [{ path: '/purchase-orders/*' }]

export const invoiceRoutes = [{ path: '/invoices/*' }, { path: '/credit-notes' }]

export const buyListRoutes = [{ path: '/buy-lists/*' }]

export const catalogRoutes = [{ path: '/catalogs/*' }]

export const supplierRoutes = [{ path: '/my-suppliers/*' }, { path: '/supplier-directory/*' }]

export const purchaserRoutes = [{ path: '/purchaser-directory/*' }, { path: '/my-purchasers/*' }]

export const inventoryRoutes = [{ path: '/stock-locations/*' }, { path: '/stocktakes/*' }, { path: '/inventory/*' }]

export const usersRoutes = [{ path: '/users/*' }]

export const systemSettingRoutes = [{ path: '/settings/*' }]

export const pointOfSaleRoutes = [{ path: '/point-of-sale/*' }]

export const myProductsRoutes = [
  { path: '/my-products/*' },
  { path: '/products/*' },
  /* Note: retired product rules */
  // { path: '/product-rules/*' },
  { path: '/barcodes/*' },
]

export const reportsRoutes = [{ path: '/reports/*' }]

export const recipeRoutes = [{ path: '/recipes/*' }, { path: '/menus/*' }, { path: '/ingredients/*' }]

export const defaultUserAccessControlList: LowercaseObjectKeys<UserAccessControlList> = {
  purchaser_can_create_all_suppliers_requisition: true,
  purchaser_can_create_single_supplier_requisition: true,
  purchaser_can_create_buy_list_requisition: true,
  purchaser_can_create_custom_requisition: true,
  purchaser_can_create_external_requisition: true,
  purchaser_can_create_reorder_requisition: true,
  purchaser_can_add_new_products_while_shopping: false,
  purchaser_can_manage_invoices_and_credit_notes: false,
  purchaser_can_manage_invoice_and_credit_note_lines: false,
  purchaser_can_export_invoices_and_credit_notes: false,
  purchaser_can_manage_purchase_orders: false,
  purchaser_can_use_override_receiving_unit_price: true,
  purchaser_can_manage_goods_receiving_notes: true,
  purchaser_can_manage_products: true,
  purchaser_can_manage_buy_lists: false,
  purchaser_can_add_items_to_buy_lists: true,
  purchaser_can_manage_sm_catalogs: false,
  purchaser_can_add_items_to_sm_catalogs: true,
  purchaser_can_manage_suppliers: false,
  purchaser_can_manage_reports: true,
  purchaser_can_manage_stocktakes: false,
  purchaser_can_close_stocktakes: false,
  purchaser_can_manage_stock_items: true,
  purchaser_can_manage_stock_locations: false,
  purchaser_can_manage_transfers: false,
  purchaser_can_manage_recipes: true,
  purchaser_can_complete_transfers: true,
  purchaser_can_create_temp_quotes: true,
  purchaser_can_manage_workflows: false,
  purchaser_can_manage_pos_items: true,
  purchaser_can_manage_batch_sale_imports: false,
  can_view_users: false,
  can_manage_users: false,
  can_manage_user_permissions: false,
  can_manage_organisations: false,
  supplier_can_manage_products: true,
  supplier_can_manage_purchasers: false,
  supplier_can_manage_catalogs: false,
  supplier_can_add_items_to_catalogs: false,
}

export const userAccessControlListAboutModalMap = (): Record<
  keyof UserAccessControlList,
  { title: string; value?: string; tooltip: string }
> => {
  return {
    PURCHASER_CAN_CREATE_ALL_SUPPLIERS_REQUISITION: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateAllSuppliersRequisitions',
        'Create All Suppliers Requisitions'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateAllSuppliersRequisitionsTooltip',
        'Indicates if the user is permitted to create a Requisition using the All Suppliers type.'
      ),
    },
    PURCHASER_CAN_CREATE_SINGLE_SUPPLIER_REQUISITION: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateSingleSupplierRequisition',
        'Create Single Supplier Requisitions'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateSingleSupplierRequisitionTooltip',
        'Indicates if the user is permitted to create a Requisition using the Single Supplier type.'
      ),
    },
    PURCHASER_CAN_CREATE_BUY_LIST_REQUISITION: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateBuyListRequisition',
        'Create Buy List Requisitions'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateBuyListRequisitionTooltip',
        'Indicates if the user is permitted to create a Requisition using the Buy List type.'
      ),
    },
    PURCHASER_CAN_CREATE_CUSTOM_REQUISITION: {
      title: i18next.t('aboutModal.userPermission.purchaserCanCreateCustomRequisition', 'Create Custom Requisitions'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateCustomRequisitionTooltip',
        'Indicates if the user is permitted to create a Requisition using the Custom type.'
      ),
    },
    PURCHASER_CAN_CREATE_EXTERNAL_REQUISITION: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateExternalRequisition',
        'Create External Requisitions'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateExternalRequisitionTooltip',
        'Indicates if the user is permitted to create a Requisition using the External type.'
      ),
    },
    PURCHASER_CAN_CREATE_REORDER_REQUISITION: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateReorderRequisition',
        'Create Re-Order Requisitions'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateReorderRequisitionTooltip',
        'Indicates if the user is permitted to create a Requisition using the Re-Order type.'
      ),
    },
    PURCHASER_CAN_ADD_NEW_PRODUCTS_WHILE_SHOPPING: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanAddNewProductsWhileShopping',
        'Add Products While Shopping'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanAddNewProductsWhileShoppingTooltip',
        'Indicates if the user is permitted to add new products while shopping a Requisition. The user will be permitted to add ad-hoc products to any Requisition type.'
      ),
    },
    PURCHASER_CAN_USE_OVERRIDE_RECEIVING_UNIT_PRICE: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanUseOverrideReceivingUnitPrice',
        'Use Override Receiving Unit Price'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanUseOverrideReceivingUnitPriceTooltip',
        'Indicates if the user is permitted to set an Override Unit Price when Receiving Goods.'
      ),
    },
    PURCHASER_CAN_MANAGE_GOODS_RECEIVING_NOTES: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanManageGoodsReceivingNotes',
        'Manage Goods Receiving Notes'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageGoodsReceivingNotesTooltip',
        'Indicates if the user is permitted to Create, Update, Complete and Cancel Goods Receiving Notes.'
      ),
    },
    PURCHASER_CAN_MANAGE_PURCHASE_ORDERS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManagePurchaseOrders', 'Manage Purchase Orders'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManagePurchaseOrdersTooltip',
        'Indicates if the user is permitted to Close and Cancel Purchase Orders.'
      ),
    },
    PURCHASER_CAN_MANAGE_INVOICES_AND_CREDIT_NOTES: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanManageInvoicesAndCreditNotes',
        'Manage Invoices and Credit Notes'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageInvoicesAndCreditNotesTooltip',
        'Indicates if the user is permitted to create, update and delete Invoices and Credit Notes. The user will be permitted to manage Invoice and Credit Note data, but will not be permitted to create, update or delete Invoice or Credit Note Lines or Sub-Lines.'
      ),
    },
    PURCHASER_CAN_MANAGE_INVOICE_AND_CREDIT_NOTE_LINES: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanManageInvoiceAndCreditNoteLines',
        'Manage Invoice and Credit Note Lines'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageInvoiceAndCreditNoteLinesTooltip',
        'Indicates if the user is permitted to create, update and delete Invoice or Credit Note Lines and Sub-Lines.'
      ),
    },
    PURCHASER_CAN_EXPORT_INVOICES_AND_CREDIT_NOTES: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanExportInvoicesAndCreditNotes',
        'Export Invoices and Credit Notes'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanExportInvoicesAndCreditNotesTooltip',
        'Indicates if the user is permitted to Export Invoices or Credit Notes and Create Invoice or Credit Note Export Files.'
      ),
    },

    PURCHASER_CAN_MANAGE_PRODUCTS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageProducts', 'Manage Products'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageProductsTooltip',
        'Indicates if the user is permitted to create and update Products owned by their Organisation or any Organisation nested beneath their Organisation.'
      ),
    },
    PURCHASER_CAN_ADD_ITEMS_TO_BUY_LISTS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanAddItemsToBuyLists', 'Add Items to Buy Lists'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanAddItemsToBuyListsTooltip',
        'Indicates if the user is permitted to add and remove products to and from Buy Lists.'
      ),
    },
    PURCHASER_CAN_MANAGE_BUY_LISTS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageBuyLists', 'Manage Buy Lists'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageBuyListsTooltip',
        'Indicates if the user is permitted to create, update and delete Buy Lists.'
      ),
    },

    PURCHASER_CAN_ADD_ITEMS_TO_SM_CATALOGS: {
      title: i18next.t(
        'aboutModal.userPermission.purchaserCanAddItemsToSmCatalogs',
        'Add Items to Self Managed Catalogs'
      ),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanAddItemsToSmCatalogsTooltip',
        'Indicates if the user is permitted to add and remove products to and from Self Managed Catalogs.'
      ),
    },
    PURCHASER_CAN_MANAGE_SM_CATALOGS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageSmCatalogs', 'Manage Self Managed Catalogs'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageSmCatalogsTooltip',
        'Indicates if the user is permitted to create, update and delete Self Managed Catalogs on behalf of a Supplier.'
      ),
    },
    PURCHASER_CAN_MANAGE_STOCK_ITEMS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageStockItems', 'Manage Stock Items'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageStockItemsTooltip',
        'Indicates if the user is permitted to create and update Stock Items owned by their Organisation.'
      ),
    },
    PURCHASER_CAN_MANAGE_STOCK_LOCATIONS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageStockLocations', 'Manage Stock Locations'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageStockLocationsTooltip',
        'Indicates if the user is permitted to create, update and delete Stock Locations.'
      ),
    },
    PURCHASER_CAN_MANAGE_TRANSFERS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageTransfers', 'Manage Transfers'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageTransfersTooltip',
        'Indicates if the user is permitted to Create a Transfer and Update a Transfer while a Transfer is Open.'
      ),
    },
    PURCHASER_CAN_COMPLETE_TRANSFERS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanCompleteTransfers', 'Complete Transfers'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCompleteTransfersTooltip',
        'Indicates if the user is permitted to mark an Open Transfer as Complete.'
      ),
    },
    PURCHASER_CAN_MANAGE_STOCKTAKES: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageStocktakes', 'Manage Stocktakes'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageStocktakesTooltip',
        'Indicates if the user is permitted to Start a Stocktake, Close an In Progress Stocktake and Transfer individual Stock Variances while a Stocktake is In Progress.'
      ),
    },
    PURCHASER_CAN_CLOSE_STOCKTAKES: {
      title: i18next.t('aboutModal.userPermission.purchaserCanCloseStocktakes', 'Close Stocktakes'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCloseStocktakesTooltip',
        'Indicates if the user is permitted to Close an In-Progress Stocktake'
      ),
    },
    PURCHASER_CAN_MANAGE_SUPPLIERS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageSuppliers', 'Manage Suppliers'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageSuppliersTooltip',
        'Indicates if the user is permitted to create, update and delete Supplier Relationships.'
      ),
    },
    PURCHASER_CAN_MANAGE_REPORTS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageReports', 'Manage Reports'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageReportsTooltip',
        'Indicates if the user is permitted to create, update and delete Reports.'
      ),
    },
    PURCHASER_CAN_MANAGE_RECIPES: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageRecipes', 'Manage Recipes'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageRecipesTooltip',
        'Indicates if the user is permitted to create, update and delete Recipes and Menus.'
      ),
    },
    PURCHASER_CAN_CREATE_TEMP_QUOTES: {
      title: i18next.t('aboutModal.userPermission.purchaserCanCreateTempQuotes', 'Create Temporary Quotes'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanCreateTempQuotesTooltip',
        'Indicates if the user is permitted to create temporary quotse.'
      ),
    },
    PURCHASER_CAN_MANAGE_POS_ITEMS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManagePosItems', 'Manage POS Items'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManagePosItemsTooltip',
        'Indicates if the user is permitted to create and update POS Items owned by their Organisation.'
      ),
    },
    PURCHASER_CAN_MANAGE_BATCH_SALE_IMPORTS: {
      title: i18next.t('aboutModal.userPermission.purchaserCanManageBatchSaleImports', 'Manage Batch Sale Imports'),
      tooltip: i18next.t(
        'aboutModal.userPermission.purchaserCanManageBatchSaleImportsTooltip',
        'Indicates if the user is permitted to create, update and complete Batch Sale Imports on behalf of their Organisation.'
      ),
    },
    CAN_VIEW_USERS: {
      title: i18next.t('aboutModal.userPermission.canViewUsers', 'View Users'),
      tooltip: i18next.t(
        'aboutModal.userPermission.canViewUsersTooltip',
        'Indicates if the user is permitted to view profile information of users in their Organisation or any Organisation nested beneath their Organisation. The user will not be able to make any changes to the profile or permissions of the user they are viewing.'
      ),
    },
    CAN_MANAGE_USERS: {
      title: i18next.t('aboutModal.userPermission.canManageUsers', 'Manage Users'),
      tooltip: i18next.t(
        'aboutModal.userPermission.canManageUsersTooltip',
        'Indicates if the user is permitted to create, update and delete users in their Organisation or any Organisation nested beneath their Organisation. The user will be able to make changes to the profile information of the user they are managing, but will not be able to modify the permissions of the user.'
      ),
    },
    CAN_MANAGE_USER_PERMISSIONS: {
      title: i18next.t('aboutModal.userPermission.canManageUserPermissions', 'Manage User Permissions'),
      tooltip: i18next.t(
        'aboutModal.userPermission.canManageUserPermissionsTooltip',
        'Indicates if the user is permitted to update the Permissions of users in their Organisation or any Organisation nested beneath their Organisation.'
      ),
    },
    CAN_MANAGE_ORGANISATIONS: {
      title: i18next.t('aboutModal.userPermission.canManageOrganisationsPermissions', 'Manage Organisations'),
      tooltip: i18next.t(
        'aboutModal.userPermission.canManageOrganisationsTooltip',
        'Indicates if the user is permitted to manage their Organisations.'
      ),
    },
    PURCHASER_CAN_MANAGE_WORKFLOWS: {
      title: i18next.t(
        'userPermissions.organisationPermissions.allowManageApprovalWorkflows',
        'Can Manage Approval Workflows'
      ),
      tooltip: i18next.t(
        'userPermissions.organisationPermissions.allowManageApprovalWorkflowsTooltip',
        'Indicates if the user can manage the Approval Workflows of any Organisation they are a member of.'
      ),
    },
    SUPPLIER_CAN_MANAGE_PRODUCTS: {
      title: i18next.t('aboutModal.userPermission.supplierCanManageProducts', 'Manage Products'),
      tooltip: i18next.t(
        'aboutModal.userPermission.supplierCanManageProductsTooltip',
        'Indicates if the user is permitted to create and update Products owned by their Organisation or any Organisation nested beneath their Organisation.'
      ),
    },
    SUPPLIER_CAN_MANAGE_CATALOGS: {
      title: i18next.t('aboutModal.userPermission.supplierCanManageCatalogs', 'Manage Catalogs'),
      tooltip: i18next.t(
        'aboutModal.userPermission.supplierCanManageCatalogsTooltip',
        'Indicates if the user is permitted to create, update and delete Catalogs.'
      ),
    },
    SUPPLIER_CAN_ADD_ITEMS_TO_CATALOGS: {
      title: i18next.t('aboutModal.userPermission.supplierCanAddItemsToCatalogs', 'Add Items to Catalogs'),
      tooltip: i18next.t(
        'aboutModal.userPermission.supplierCanAddItemsToCatalogsTooltip',
        'Indicates if the user is permitted to add and remove products to and from Catalogs.'
      ),
    },
    SUPPLIER_CAN_MANAGE_PURCHASERS: {
      title: i18next.t('aboutModal.userPermission.supplierCanManagePurchasers', 'Manage Purchasers'),
      tooltip: i18next.t(
        'aboutModal.userPermission.supplierCanManagePurchasersTooltip',
        'Indicates if the user is permitted to create, update and delete Purchasers.'
      ),
    },
  }
}

// TODO: Will use the list from BE.
export const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabw', code: 'ZN' },
]

export const TableHorizontalBarChartColours = [
  'bg-blue',
  'bg-[#5970ee]',
  'bg-[#7c50e8]',
  'bg-[#913fe4]',
  'bg-[#a130df]',
]

export const invoiceThresholdPreferences = () => {
  return [
    { id: 1, title: i18next.t('general.lenient', 'Lenient'), value: true },
    { id: 2, title: i18next.t('general.strict', 'Strict'), value: false },
  ]
}

export const phoneRegex = /^(([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+)?$/
