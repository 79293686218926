import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import useFindMatchRoutes from '@/modules/shared/hooks/useFindMatchRoutes'
import { HomeIcon } from '@/modules/shared/icons/HomeIcon'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import CreatePurchaseOrderModal from '@/modules/shared/layouts/side-navigation/CreatePurchaseOrderModal'
import { AccountSideBarItems } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/AccountSideBarItems'
import { BuyListSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/BuyListSideBarItem'
import { CatalogsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/CatalogsSideBarItem'
import { InventorySideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/InventorySideBarItem'
import { InvoicesSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/InvoicesSideBarItem'
import { PointOfSaleSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PoinOfSaleSideBarItem'
import { ProductSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ProductSideBarItem'
import { PurchaseOrdersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaseOrdersSideBarItem'
import { RecipesSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/RecipesSideBarItem'
import { ReportsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ReportsSideBarItem'
import { RequisitionsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/RequisitionsSideBarItem'
import { SuppliersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/SuppliersSideBarItem'
import { UsersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/UsersSideBarItem'
import { classNames } from '@/modules/shared/utils/classNames'

export interface SideBarItem {
  openAccordionKey: number | undefined
  sideBarIndexKey: number
  changeOpenAccordionKey: (key: number) => void
  onCloseDrawer: () => void
}

export default function PurchaserNavigation() {
  const sideBar = useContext(SideBarContext)

  const { t } = useTranslation()
  const { userPermissions, currentOrganisation } = useSessionUser()

  const {
    requisitionRoutesMatch,
    purchaseOrdersRoutesMatch,
    invoiceRoutesMatch,
    buyListRoutesMatch,
    catalogRoutesMatch,
    supplierRoutesMatch,
    inventoryRoutesMatch,
    accordionDefaultKey,
    myProductRoutesMatch,
    reportsRoutesMatch,
    recipeRoutesMatch,
    usersRoutesMatch,
    pointOfSaleRoutesMatch,
    sideBarIndexKeys,
  } = useFindMatchRoutes()

  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] = useState(false)

  const {
    inventory: showInventoryTab,
    showBuyListsTab,
    showCatalogsTab,
    showInvoicesTab,
    showProductsTab,
    showPurchaseOrdersTab,
    showRequisitionsTab,
    showSuppliersTab,
    showReportsTab,
    showRecipesTab,
    showPointOfSaleTab,
  } = currentOrganisation?.featureFlags || {}

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md px-3 transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="size-7" />
            {t('general.home', 'Home')}
          </NavLink>
          {showRequisitionsTab && (
            <RequisitionsSideBarItem
              requisitionRoutesMatch={requisitionRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.requisitionSideBarKey}
            />
          )}
          {showPurchaseOrdersTab && (
            <PurchaseOrdersSideBarItem
              purchaseOrdersRoutesMatch={purchaseOrdersRoutesMatch}
              openAccordionKey={openAccordionKey}
              setShowCreatePurchaseOrderModal={setShowCreatePurchaseOrderModal}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.purchaseOrdersSideBarKey}
            />
          )}
          {showInvoicesTab && (
            <InvoicesSideBarItem
              invoiceRoutesMatch={invoiceRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.invoiceSideBarKey}
            />
          )}
          {showBuyListsTab && (
            <BuyListSideBarItem
              buyListRoutesMatch={buyListRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.buyListSideBarKey}
            />
          )}
          {showCatalogsTab && (
            <CatalogsSideBarItem
              catalogRoutesMatch={catalogRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.catalogSideBarKey}
            />
          )}
          {showProductsTab && (
            <ProductSideBarItem
              myProductRoutesMatch={myProductRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.myProductSideBarKey}
            />
          )}
          {showRecipesTab && (
            <RecipesSideBarItem
              recipeRoutesMatch={recipeRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.recipeSideBarKey}
            />
          )}
          {showInventoryTab && (
            <InventorySideBarItem
              inventoryRoutesMatch={inventoryRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.inventorySideBarKey}
            />
          )}
          {showPointOfSaleTab && (
            <PointOfSaleSideBarItem
              pointOfSaleRoutesMatch={pointOfSaleRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.pointOfSaleSideBarKey}
            />
          )}
          {showSuppliersTab && (
            <SuppliersSideBarItem
              supplierRoutesMatch={supplierRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.supplierSideBarKey}
            />
          )}
          {showReportsTab && userPermissions?.PURCHASER_CAN_MANAGE_REPORTS && (
            <ReportsSideBarItem
              reportsRoutesMatch={reportsRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.reportsSideBarKey}
            />
          )}
          {(userPermissions?.CAN_VIEW_USERS || userPermissions?.CAN_MANAGE_USERS) && (
            <UsersSideBarItem
              usersRoutesMatch={usersRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.usersSideBarKey}
            />
          )}
        </section>
        <AccountSideBarItems
          openAccordionKey={openAccordionKey}
          changeOpenAccordionKey={changeOpenAccordionKey}
          onCloseDrawer={onCloseDrawer}
        />
      </nav>

      <CreatePurchaseOrderModal
        showModal={showCreatePurchaseOrderModal}
        setShowModal={setShowCreatePurchaseOrderModal}
      />
    </>
  )
}
