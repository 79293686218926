import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetOrganisationChildrenDocument = gql`
  query GetOrganisationChildren($organisationId: Int!) {
    organisation(id: $organisationId) {
      id
      name
      legalEntity
      departmentCode
      defaultAccountId
      isVerified
      isAPurchaser
      postalAddressCountry
      ... on Purchaser {
        invoiceWorkflow {
          id
          name
        }
      }
      children {
        totalCount
        unpaginated {
          id
          name
          legalEntity
          departmentCode
          defaultAccountId
          isVerified
          children {
            totalCount
          }
          ... on Purchaser {
            invoiceWorkflow {
              id
              name
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetOrganisationChildrenQuery, Types.GetOrganisationChildrenQueryVariables>
